/* eslint-disable no-unused-vars */
import { graphql } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"

import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"
import OSquadByPosition from "gatsby-component-lib/components-legacy/organisms/o-squad-by-position"
import {
  peopleGrouper,
  squadSubNavigation,
  clean_fullslug,
} from "gatsby-component-lib"
import TContentBody from "gatsby-component-lib/components-legacy/technical/t-content-body"

export const query = graphql`
  query TeamPageTemplateQuery(
    $team_uid: String
    $website: String
    $uid: String
  ) {
    teamPage: typo3Page(uid: { eq: $uid }) {
      content
    }
    squad: allTypo3Page(filter: { uid: { eq: $team_uid } }) {
      edges {
        node {
          uid
          slug
          full_slug
          type
          name
          data {
            season {
              id
              title
              slug
            }
            players {
              person {
                id
                uid
                title
                slug
                full_slug
                position
                jersey_number
              }
            }
          }
        }
      }
    }
    peopleData: allTypo3Page(
      filter: { type: { in: ["person_page"] }, website: { in: [$website] } }
    ) {
      edges {
        node {
          data {
            person_uid
            transparent_image
          }
        }
      }
    }
  }
`
const TeamPageTemplate = ({ data, pageContext }) => {
  const {
    node,
    subnavigation,
    full_slug,
    website,
    website_lang,
    website_theme,
    tree,
    footerPageNodeContent,
  } = pageContext

  const { t } = useTranslation()

  let related_data = null
  if (data.squad.edges.length > 0) {
    // related_data = Ergebnis der Sub-Query (oben)
    related_data = data.squad.edges[0].node.data
  }

  let people = []
  let season = false
  if (related_data) {
    // Personen werden zuerst nach der Rückennummer sortiert
    people = related_data.players.sort(function(a, b) {
      return a.person.jersey_number < b.person.jersey_number ? -1 : 1
    })
    season = related_data.season
  }

  const content = JSON.parse(data.teamPage.content)

  let breadcrumbs = [
    {
      name: t("Websites." + website),
      full_slug: "/",
    },
    { name: t("Team"), full_slug: full_slug },
  ]

  // Personen werden nach Bereich (Tor, Abwehr, Mittelfeld, Sturm) gruppiert
  let peopleData = data.peopleData.edges
  let mappedPeople = {}
  for (let i in people) {
    // PersonData ist die Typo3 Seite des Spielers (wir brauchen das transparente Bild)
    let personData = false
    personData = peopleData.filter(
      edge =>
        edge.node.data !== null &&
        edge.node.data.person_uid === people[i]["person"]["uid"]
    )

    // Gruppierung der Bereiche (Tor, Abwehr, Mittelfeld, Sturm)
    if (!mappedPeople[peopleGrouper(people[i]["person"]["position"])]) {
      mappedPeople[peopleGrouper(people[i]["person"]["position"])] = []
    }
    // Wenn die Spieler-Typo3 Seite gefunden wurde
    //   => transparent_image in das Objekt mergen
    //   ansonsten: key leer mergen
    if (personData && personData[0] && personData[0].node) {
      mappedPeople[peopleGrouper(people[i]["person"]["position"])].push(
        Object.assign(people[i]["person"], {
          transparent_image: personData[0].node.data.transparent_image,
        })
      )
    } else {
      mappedPeople[peopleGrouper(people[i]["person"]["position"])].push(
        Object.assign(people[i]["person"], {
          transparent_image: "",
        })
      )
    }
  }

  // DropDown oben rechts
  let subnavigationDropdown1 = []
  subnavigationDropdown1.push({
    link: full_slug,
    title: t("Dropdowns.AllPlayers"),
    active: false,
  })
  for (let i in people) {
    let p = people[i]
    subnavigationDropdown1.push({
      link: clean_fullslug(p.person.full_slug),
      title: p.person.jersey_number + " " + p.person.title,
      active: false,
    })
  }

  let title =
    content.title + " " + (season ? season.title : "").replace("/", "-")

  return (
    <OLayout
      tree={tree}
      website={website}
      website_lang={website_lang}
      content={{ parents: breadcrumbs }}
      breadcrumbs={breadcrumbs}
      subnavigation={subnavigation}
      subnavigationDropdown1={subnavigationDropdown1}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
    >
      <TSeo
        website_theme={website_theme}
        website={website}
        content={content}
        full_slug={node.full_slug}
        title={title}
      />
      <main className="ef-content ef-content--squad">
        <header className="ef-content__header">
          <div className="ef-container ef-container--l">
            <h1 className="ef-heading ef-heading--2">{content.headline}</h1>
          </div>
        </header>

        {Object.keys(mappedPeople).map(key => (
          <OSquadByPosition
            key={key}
            people={mappedPeople[key]}
            position={key}
          />
        ))}
        <div className="ef-content__body">
          <TContentBody content={content} />
        </div>
      </main>

      <MShare title={title} website={website} />
    </OLayout>
  )
}

export default TeamPageTemplate
